<template>
  <div class="view-root">
    <p class="T_medium-heading">
      {{ $t('Logout.heading') }}
    </p>
    <div>
      <ds-button
        size="small"
        :to="{ name: 'Login' }"
      >
        OK
      </ds-button>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('session/logout')
  }
}
</script>

<style lang="scss" scoped>
.view-root {
  padding: 50px 30px;
  text-align: center;
}
</style>

import api from '@/plugins/api'

const state = {
  prices: {},
  invoices: []
}
const getters = {
  price: (state) => (isYearly) => Object.values(state.prices)
    .find((item) => isYearly ? item.recurring.interval === 'year' : item.recurring.interval === 'month')
}
const mutations = {
  prices (state, payload) {
    state.prices = payload
  },
  invoices (state, payload) {
    state.invoices = payload
  },
  reset (state) {
    state.prices = {}
    state.invoices = []
  }
}
const actions = {
  async fetchPrices (context) {
    const result = (await api.get('/v1/premium/prices')).data
    context.commit('prices', result)
  },
  async edit (context, payload) {
    const result = (await api.put('/v1/account', payload)).data
    context.commit('session/updateUser', result, { root: true })
    return result
  },
  async refresh (context) {
    const result = (await api.get('/v1/account')).data
    context.commit('session/updateUser', result, { root: true })
    return result
  },
  async delete () {
    return (await api.delete('/v1/account')).data
  },
  async resetPassword (context, { email }) {
    return (await api.post('/v1/auth/lost', { email })).data
  },
  async setPassword (context, { token, password }) {
    return (await api.put('/v1/auth/set', { token, password })).data
  },
  async createCheckoutSession (context, { priceId }) {
    const properties = {
      locale: context.rootGetters['session/ietfLocale'],
      priceId
    }
    return (await api.post('/v1/premium/checkout', properties)).data
  },
  async verifyCheckoutSession (context, { sessionId }) {
    return (await api.get(`/v1/premium/verify/${sessionId}`)).data
  },
  async portal (context) {
    return (await api.post('/v1/premium/manage')).data
  },
  async fetchInvoices (context) {
    const invoices = (await api.get('/v1/account/invoices')).data
    context.commit('invoices', invoices)
  },
  async fetchSubscription (context) {
    return (await api.get('/v1/premium/subscription')).data
  },
  async cancelSubscription (context) {
    return (await api.delete('/v1/premium/subscription')).data
  }
}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000/',
  responseType: 'json',
  withCredentials: true // enable cookies
})

api.interceptors.response.use((r) => r, (e) => {
  if (e.response && e.response.data && e.response.data.error) {
    // override the error thrown by axios
    // with the error message+code from the API
    const err = new Error(e.response.data.error)
    err.code = e.response.data.code
    throw err
  }

  throw e
})

export default api

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes.js'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const authRoutes = ['Auth', 'Logout', 'Signup', 'Login', 'WithoutAccount', 'ForgotPassword', 'ResetPassword']

router.beforeEach((to, from, next) => {
  store.commit('session/resetIdle')

  if (store.state.session.checked) {
    if (to.name === 'Auth') {
      // auth is already checked. skip auth to avoid double session init.
      next({ name: 'Conferences' })
      return
    }

    next()
    return
  }

  // whitelist
  if (authRoutes.includes(to.name)) {
    next()
    return
  }

  // redirect all to auth
  next({ name: 'Auth', params: { redirect: to } })
})

export default router

export { authRoutes }

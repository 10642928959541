<template>
  <div>
    <transition
      mode="out-in"
      name="slide-y"
    >
      <AppHeader
        v-if="hasHeader"
        id="app-header"
        v-model="isProfileDropdownShown"
        class="app-header"
      />
    </transition>
    <main
      class="app-main"
      :class="{ 'app-main--with-header': hasHeader }"
    >
      <router-view />
      <vue-progress-bar />
    </main>
    <div
      v-if="isErrorPopinOpened"
      class="error-popin-frame"
    >
      <div class="error-popin-box">
        <p class="error-popin-heading">
          {{ $t(`Common.${popinType}`) }}
        </p>
        <p
          v-if="errorPopinMessage"
          class="error-popin-message"
        >
          {{ errorPopinMessage }}
        </p>
        <div class="error-popin-close-button-container">
          <ds-button
            size="medium"
            danger
            outlined
            @click="$store.commit('app/closeErrorPopin')"
          >
            {{ $t('Common.close') }}
          </ds-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '@/components/AppHeader.vue'
import { authRoutes } from '@/router/index.js'

export default {
  components: {
    AppHeader
  },
  data () {
    return {
      isProfileDropdownShown: false
    }
  },
  computed: {
    ...mapState('app', {
      isErrorPopinOpened: (state) => state.isErrorPopinOpened,
      errorPopinMessage: (state) => state.errorPopinMessage,
      popinType: (state) => state.popinType
    }),
    hasHeader () {
      return this.$store.state.session.checked && !authRoutes.includes(this.$route.name)
    }
  },
  watch: {
    isErrorPopinOpened: {
      handler (value) {
        const zIndex = value ? '1' : '2'
        const appHeaderElement = window.document.getElementById('app-header')

        if (appHeaderElement) {
          appHeaderElement.style.zIndex = zIndex
        }
      },
      immediate: true
    }
  },
  created () {
    this.$Progress.start()

    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
  mounted () {
    this.$Progress.finish()
  }
}
</script>

<style>
.slide-enter-active, .slide-leave-active, .slide-y-enter-active, .slide-y-leave-active {
  transition: opacity 0.5s, transform 0.5s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-enter, .slide-leave-to {
  opacity: 0.2;
  transform: translateX(-5%);
}

.slide-y-enter, .slide-y-leave-to {
  opacity: 0.5;
  transform: translateY(-100%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to  {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.app-header {
  position: fixed;
  z-index: $z-app-header;
  top: 0;
  left: 0;
  right: 0;
}

.app-main {
  position: relative;
  z-index: $z-app-main;

  &.app-main--with-header {
    top: $app-header-height;
  }
}

.error-popin-frame {
  position: fixed;
  z-index: $z-overlay-shadow;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.error-popin-box {
  padding: 30px 20px;
  width: 400px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  overflow-y: auto;
}

.error-popin-heading {
  color: $color-red;
  font-weight: 600;
  font-size: 18px;
}

.error-popin-message {
  margin-top: 20px;
}

.error-popin-close-button-container {
  margin-top: 40px;
}
</style>

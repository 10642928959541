<template>
  <router-link
    v-if="to"
    :class="cssClasses"
    :to="to"
  >
    <slot />
  </router-link>
  <a
    v-else-if="href"
    :class="cssClasses"
    :href="href"
    :target="newtab ? '_blank' : null"
    :rel="newtab ? 'noopener noreferrer' : null"
  >
    <slot />
  </a>
  <button
    v-else
    :class="cssClasses"
    :type="type"
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'DsButton',
  props: {
    size: {
      type: String,
      validator (value) {
        return ['small', 'medium', 'large', 'big'].includes(value)
      },
      required: true
    },
    to: {
      type: [String, Object],
      default: null
    },
    href: {
      type: String,
      default: null
    },
    newtab: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    fluid: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssClasses () {
      const result = ['DS_button']

      if (['small', 'medium', 'large', 'big'].includes(this.size)) {
        result.push(`DS_button--${this.size}`)
      } else {
        console.warn('DsButton: size prop value is invalid. Must be "small", "medium", "large" or "big"')
      }
      if (this.fluid) {
        result.push('DS_button--fluid')
      }
      if (this.white) {
        result.push('DS_button--white')
      }
      if (this.danger) {
        result.push('DS_button--danger')
      }
      if (this.outlined) {
        result.push('DS_button--outlined')
      }
      if (this.loading) {
        result.push('DS_button--loading')
      }
      return result
    }
  }
}
</script>

<template>
  <header class="header">
    <div class="header-inner-below-small">
      <router-link
        to="/conferences"
        class="A_icon-box A_icon-box--white"
      >
        <img
          src="@/assets/img/home.svg"
          width="24"
          height="24"
        >
      </router-link>
      <div>
        <ds-button
          v-if="!$store.state.session.userPremium"
          size="small"
          to="/profile"
          style="margin-right: 20px;"
        >
          {{ $t('AppHeader.goPremium') }}
        </ds-button>
        <button
          class="user-initials-button"
          @click="internalIsProfileDropdownShown = true"
        >
          <span>
            {{ userInitials }}
          </span>
        </button>
      </div>
      <div
        v-if="internalIsProfileDropdownShown"
        class="mobile-dropdown-shadow"
      />
      <div
        v-show="internalIsProfileDropdownShown"
        ref="mobileDropdown"
        class="mobile-dropdown"
      >
        <div class="mobile-dropdown-top-container">
          <div>
            <p class="mobile-dropdown-hello">
              {{ $t('Common.hello') }}
            </p>
            <p class="mobile-dropdown-user">
              {{ $store.state.session.guest ? $store.state.session.userEmail : $store.state.session.userName }}
            </p>
          </div>
          <button
            class="user-initials-button"
            @click="internalIsProfileDropdownShown = false"
          >
            <span>
              {{ userInitials }}
            </span>
          </button>
        </div>
        <hr class="mobile-dropdown-hr">
        <ul class="mobile-dropdown-list">
          <li
            v-if="!$store.state.session.guest"
            class="mobile-dropdown-item"
          >
            <router-link
              to="/profile"
              @click.native="internalIsProfileDropdownShown = false"
            >
              {{ $t('AppHeader.account') }}
            </router-link>
          </li>
          <li
            v-if="!$store.state.session.guest"
            class="mobile-dropdown-item"
          >
            <router-link
              to="/profile"
              @click.native="internalIsProfileDropdownShown = false"
            >
              {{ $t('AppHeader.bills') }}
            </router-link>
          </li>
          <li class="mobile-dropdown-item mobile-dropdown-item--logout">
            <router-link to="/logout">
              {{ $t('AppHeader.logout') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="header-inner-above-small">
      <router-link to="/auth">
        <ConfcallrLogo height="21" />
      </router-link>
      <div class="links-container">
        <router-link
          class="link"
          to="/conferences"
        >
          {{ $t('AppHeader.conferences') }}
        </router-link>
        <!-- <router-link
          class="link"
          to="/recordings"
        >
          {{ $t('AppHeader.recordings') }}
        </router-link> -->
      </div>
      <div
        v-if="$store.state.session.checked"
        ref="desktopDropdownContainer"
      >
        <button
          class="profile-button"
          :class="{ 'profile-button--is-dropdown-shown': internalIsProfileDropdownShown }"
          @click="internalIsProfileDropdownShown = !internalIsProfileDropdownShown"
        >
          <div
            v-if="$store.state.session.guest"
            class="profile-button-guest-container"
          >
            <p class="profile-button-name">
              {{ $t('AppHeader.guestLabel') }}
            </p>
            <p class="profile-button-guest-email">
              {{ $store.state.session.userEmail }}
            </p>
          </div>
          <p
            v-else
            class="profile-button-name"
          >
            {{ $store.state.session.userName }}
          </p>
          <router-link
            v-if="!$store.state.session.userPremium"
            to="/profile"
            class="profile-button-go-premium-link"
            @click.native.stop="internalIsProfileDropdownShown = false"
          >
            {{ $t('AppHeader.goPremium') }}
          </router-link>
          <div
            v-else-if="!$store.state.session.guest"
            class="profile-button-is-premium-badge"
          >
            {{ $t('AppHeader.isPremiumBadge') }}
          </div>
          <img
            v-if="internalIsProfileDropdownShown"
            src="@/assets/img/triangle-white-up.svg"
            class="profile-button-triangle"
            width="16"
            height="16"
          >
          <img
            v-else
            src="@/assets/img/triangle-gray-down.svg"
            class="profile-button-triangle"
            width="16"
            height="17"
          >
        </button>
        <div
          v-show="internalIsProfileDropdownShown"
          class="desktop-dropdown"
        >
          <ul class="desktop-dropdown-list">
            <li
              v-if="!$store.state.session.guest"
              class="desktop-dropdown-item"
            >
              <router-link
                to="/profile"
                @click.native="internalIsProfileDropdownShown = false"
              >
                {{ $t('AppHeader.account') }}
              </router-link>
            </li>
            <li
              v-if="!$store.state.session.guest"
              class="desktop-dropdown-item"
            >
              <router-link
                to="/profile"
                @click.native="internalIsProfileDropdownShown = false"
              >
                {{ $t('AppHeader.bills') }}
              </router-link>
            </li>
            <li class="desktop-dropdown-item desktop-dropdown-item--logout">
              <router-link to="/logout">
                {{ $t('AppHeader.logout') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  components: {
    ConfcallrLogo
  },
  model: {
    prop: 'isProfileDropdownShown',
    event: 'change'
  },
  props: {
    isProfileDropdownShown: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('session', {
      userInitials: (state) => {
        if (!state.userName) {
          return state.userEmail[0].toUpperCase() || 'A'
        }
        const firstInitial = state.userName[0].toUpperCase()
        const splitted = state.userName.split(' ')
        if (splitted.length < 2) {
          return firstInitial
        }
        const secondInitial = splitted[splitted.length - 1][0].toUpperCase()
        return firstInitial + secondInitial
      }
    }),
    internalIsProfileDropdownShown: {
      get () {
        return this.isProfileDropdownShown
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  watch: {
    $route () {
      this.internalIsProfileDropdownShown = false
    }
  },
  mounted () {
    document.addEventListener('mousedown', this.handleMousedown)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', this.handleMousedown)
  },
  methods: {
    handleMousedown (event) {
      if (
        this.$refs.mobileDropdown.contains(event.target) === false &&
        this.$refs.desktopDropdownContainer.contains(event.target) === false
      ) {
        this.internalIsProfileDropdownShown = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  overflow-y: hidden;
}

/*
** Visible below small breakpoint
*/

.header-inner-below-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: $app-header-height;

  @include above(small) {
    display: none;
  }
}

.menu-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 10px;

  span {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
  }
}

.user-initials-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 41px;
  height: 41px;
  background-color: $color-gray-light;
  border: 1px solid #8b8b96;
  border-radius: 50%;

  span {
    position: relative;
    top: 1px;
    color: $color-gray-dark;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
  }
}

.mobile-dropdown-shadow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(28, 28, 36, 0.4);
}

.mobile-dropdown {
  position: fixed;
  top: 0;
  right: 0;
  padding: 12px 20px 30px 30px;
  width: 300px;
  background-color: white;
  border-radius: 0 0 0 20px;
}

.mobile-dropdown-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-dropdown-hello {
  color: $color-gray-dark;
  font-size: 12px;

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-dropdown-user {
  font-weight: 600;
  letter-spacing: 0.1px;

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-dropdown-hr {
  margin-top: 12px;
  margin-bottom: 30px;
  border: none;
  border: 1px solid $color-gray-light;
}

.mobile-dropdown-list {
  list-style: none;
}

.mobile-dropdown-item {
  &:not(:first-child) {
    margin-top: 30px;
  }

  font-weight: 600;
  letter-spacing: 0.1px;

  &.mobile-dropdown-item--logout {
    color: $color-red;
  }
}

/*
** Visible above small breakpoint
*/

.header-inner-above-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: $app-header-height;

  @include below(small) {
    display: none;
  }
}

.links-container {
  display: flex;
}

.link {
  position: relative;
  width: 122px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-align: center;

  &:not(:first-child) {
    margin-left: 40px;
  }

  &.router-link-active {
    color: $color-blue;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 2px;
      background-color: $color-blue;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
}

.profile-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 10px;

  &.profile-button--is-dropdown-shown {
    background-color: $color-blue;

    .profile-button-name,
    .profile-button-guest-email {
      color: white ;
    }
  }
}

.profile-button-name {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.profile-button-guest-container {
  text-align: left;
}

.profile-button-guest-email {
  color: $color-gray-soft;
  font-size: 11px;
  line-height: 17px;
}

.profile-button-go-premium-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  padding: 5px 10px;
  background-color: $color-blue;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;

  .profile-button--is-dropdown-shown & {
    background-color: white;
    color: $color-fake-black;
  }
}

.profile-button-is-premium-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #1c1c24;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
}

.profile-button-triangle {
  margin-top: -3px;
  margin-left: 15px;
}

.desktop-dropdown {
  position: fixed;
  top: $app-header-height;
  right: 30px;
  padding: 20px;
  width: 220px;
  background-color: white;
  border: 1px solid $color-gray-light;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.1);

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    right: 12px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-top: 1px solid $color-gray-light;
    border-left: 1px solid $color-gray-light;
    transform: rotate(45deg);
  }
}

.desktop-dropdown-list {
  list-style: none;
}

.desktop-dropdown-item {
  &:not(:first-child) {
    margin-top: 20px;
  }

  letter-spacing: 0.1px;

  &.desktop-dropdown-item--logout {
    color: $color-red;
    font-weight: 600;
  }
}
</style>

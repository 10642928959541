import store from './store'

function oldestConnected (connected) {
  const values = Object.values(connected)

  if (!values.length) {
    return null
  }

  if (values.length === 1) {
    return parseDt(values[0].date_connection)
  }

  const oldestConnected = (acc, cur) => acc.date_connection < cur.date_connection ? acc : cur
  return parseDt(values.reduce(oldestConnected).date_connection)
}

function dtFormat (value, options) {
  let date

  if (typeof value === 'string') {
    date = parseDt(value)

    if (date === null) {
      return value
    }
  } else if (value === undefined || isNaN(value)) {
    return '?'
  } else {
    date = value
  }

  if (options === undefined) {
    options = {
    // weekday: false,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      // timeZone: 'Europe/Paris', // use browser tz
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric'
    // second: 'numeric'
    }
  }

  return (new Intl.DateTimeFormat(store.getters['session/jsLocale'], options).format(date))
}

function parseDt (str) {
  if (!str) return null

  if (str.substr(0, 4) === '0000') return null

  // 2020-12-30 12:36:42
  // 0123456789ABCDEFGH

  const date = new Date(Date.UTC(
    str.substr(0, 4),
    Number(str.substr(5, 2)) - 1,
    str.substr(8, 2),
    str.substr(11, 2),
    str.substr(14, 2),
    str.substr(17, 2)
  ))

  return date
}

export {
  oldestConnected,
  parseDt,
  dtFormat
}

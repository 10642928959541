/* eslint-disable import/first */

/**
 * Style
 */

import 'resetr.css'
import '@/assets/scss/main.scss'

/**
 * Design System
 */

import '@/design-system'

/**
 * Vue.js
 */

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueLoading from 'vue-loading-template'
import VueProgressBar from 'vue-progressbar'

/**
 * V-Calendar
 * https://vcalendar.io
 */

import VCalendar from 'v-calendar'

Vue.use(VCalendar)

/**
 * i18n
 */

import VueI18n from 'vue-i18n'
import { defaultLocale, messages } from '@/i18n'

Vue.use(VueLoading)

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: messages
})

/**
 * v-clipboard
 * https://www.npmjs.com/package/v-clipboard
 */

import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

/**
 * Progress bar
 */

const options = {
  color: '#20bf6b',
  failedColor: '#874b4b',
  thickness: '4px',
  transition: {
    speed: '0.5s',
    opacity: '0.6s',
    termination: 400
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)

/**
 * filters
 */
import { oldestConnected, dtFormat } from '@/helpers'

Vue.filter('duration', (value) => {
  value /= 1000

  let hours = Math.floor(value / 3600).toFixed(0)
  let minutes = Math.floor(value / 60).toFixed(0)
  let seconds = Math.floor(value % 60).toFixed(0)

  if (hours.length === 1) hours = `0${hours}`
  if (minutes.length === 1) minutes = `0${minutes}`
  if (seconds.length === 1) seconds = `0${seconds}`

  return `${hours}:${minutes}:${seconds}`
})

Vue.filter('datetime', dtFormat)
Vue.filter('oldest', oldestConnected)

/**
 * Stripe
 */
import '@stripe/stripe-js'

/**
 * Create Vue Instance
 */

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

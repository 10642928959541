import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import session from './session'
import rooms from './rooms'
import account from './account'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: {
    app,
    session,
    rooms,
    account
  }
})

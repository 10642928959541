import Auth from '@/views/Auth.vue'
import Logout from '@/views/Logout.vue'
import NotFound from '@/views/NotFound.vue'

export default [
  {
    path: '/',
    redirect: { name: 'Conferences' }
  },
  {
    path: '/start/:email',
    redirect: { name: 'Auth' }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Signup.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/without-account',
    name: 'WithoutAccount',
    component: () => import(/* webpackChunkName: "login" */ '@/views/WithoutAccount.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "login" */ '@/views/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "login" */ '@/views/ResetPassword.vue')
  },
  {
    path: '/conferences',
    name: 'Conferences',
    component: () => import(/* webpackChunkName: "conferences" */ '@/views/Conferences.vue')
  },
  {
    path: '/conferences/:id',
    name: 'Conference',
    component: () => import(/* webpackChunkName: "conferences" */ '@/views/Conference.vue')
  },
  // {
  //   path: '/scheduled-conference',
  //   name: 'ScheduledConference',
  //   component: () => import(/* webpackChunkName: "scheduled-conference" */ '@/views/ScheduledConference.vue')
  // },
  // {
  //   path: '/recordings',
  //   name: 'Recordings',
  //   component: () => import(/* webpackChunkName: "recordings" */ '@/views/Recordings.vue')
  // },
  // {
  //   path: '/create-conference',
  //   name: 'CreateConference',
  //   component: () => import(/* webpackChunkName: "conferences" */ '@/views/CreateConference.vue')
  // },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    path: '/profile/payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Stripe.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

import router from '@/router'
import api from '@/plugins/api'
import { parseDt } from '@/helpers'

const DEFAULT_LOCALE = 'fr_FR' // for now
const DEFAULT_COUNTRY = 'FR' // for now
const AUTH_INTERVAL_CHECK = 60000 // 1 minute
const IDLE_INTERVAL_CHECK = 600000 // 10 minutes
const IDLE_LOGOUT = 57600000 // 16 hours

const ls = window.localStorage
const session = JSON.parse(ls.getItem('session')) || {}

let idleIntervalId = 0
let initialized = false

export default {
  namespaced: true,

  getters: {
    jsLocale: (state) => state.userLocale.replace('_', '-'),
    ietfLocale: (state) => state.userLocale.substr(0, 2),
    userPremiumEndsAt: (state) => state.userPremiumEndsAt !== null ? parseDt(state.userPremiumEndsAt) : null,
    hasStripeSubscription: (state) => !!state.stripeSubId,
    freeConcurrentConnected: () => process.env.VUE_APP_FREE_CONCURRENT_CONNECTED || 4,
    freeCallLimitMinutes: () => process.env.VUE_APP_FREE_CALL_LIMIT_MINUTES || 45
  },
  state: {
    idle: Date.now(),
    valid: session.valid || false,
    checked: false, // not loaded from local storage => Auth.vue mandatory
    guest: Object.prototype.hasOwnProperty.call(session, 'guest') ? session.guest : true,
    stripeSubId: session.stripeSubId || '',
    userName: session.userName || '',
    userEmail: session.userEmail || '',
    userCompany: session.userCompany || '',
    userLocale: session.userLocale || DEFAULT_LOCALE, // POSIX en_US fr_FR
    userPremium: session.userPremium || false,
    userPremiumEndsAt: session.userPremiumEndsAt || null,
    userAddressStreet: session.userAddressStreet || '',
    userAddressState: session.userAddressState || '',
    userAddressZip: session.userAddressZip || '',
    userAddressCity: session.userAddressCity || '',
    userAddressCountry: session.userAddressCountry || DEFAULT_COUNTRY,
    countries: JSON.parse(ls.getItem('countries')) || []
  },
  actions: {
    async authCheck (context) {
      // session server side check
      // (actually client side in the cookie but with a sig w/ secret)

      // I may have a valid cookie (/auth/check will pass)
      // but maybe my loaded session isn't good.
      if (!context.state.valid) {
        // my session is not valid,
        // make sure I dont have a cookie
        await api.post('/v1/auth/logout')
        return false
      }

      // my local session looks good
      // am I a guest? if so, I dont have a cookie
      // so we're good!
      if (context.state.guest) {
        // mark the current session as checked
        context.commit('authChecked')
        // make sure I dont have a cookie
        await api.post('/v1/auth/logout')
        //
        return true
      }

      // my local session appears to be good
      // let's check the cookie now
      const valid = (await api.post('/v1/auth/check')).data

      if (valid) {
        // mark the current session as checked
        context.commit('authChecked')
        // refresh the user
        // with this, what is the point of having the session in localStorage?
        // answer: useful for guest sessions! (no cookie)
        await context.dispatch('account/refresh', {}, { root: true })
        // run the next check
        window.setTimeout(() => context.dispatch('authCheck'), AUTH_INTERVAL_CHECK)
        //
        return true
      }

      // mark as not checked
      context.commit('logout')
      router.push({ name: 'Logout' })

      return false
    },
    async loginAsGuest (context, { email }) {
      context.commit('loginAsGuest', { email })
      await context.dispatch('init')
    },
    async login (context, { login, password }) {
      // this will throw an exception if auth is not valid
      const response = await api.post('/v1/auth/login', {
        login,
        password
      })
      context.commit('login', response.data)
      // program auth check
      window.setTimeout(() => context.dispatch('authCheck'), AUTH_INTERVAL_CHECK)
      // init
      await context.dispatch('init')
      //
      return true
    },
    async register (context, { name, email, password }) {
      // this will throw an exception if something is not valid
      const response = await api.post('/v1/auth/register', {
        name,
        email,
        password
      })
      context.commit('login', response.data)
      // program auth check
      window.setTimeout(() => context.dispatch('authCheck'), AUTH_INTERVAL_CHECK)
      // init
      await context.dispatch('init')
    },
    async logout (context) {
      // delete local session
      context.commit('logout')
      // reset other modules
      context.commit('rooms/reset', null, { root: true })
      context.commit('account/reset', null, { root: true })
      // delete remote cookie
      await api.post('/v1/auth/logout')
    },
    async countries (context) {
      if (!context.state.countries || context.state.countries.length === 0) {
        const countries = (await api.get('/v1/lists/countries')).data
        context.commit('countries', countries)
      }
    },
    async init (context) {
      if (initialized) {
        console.warn('session already initalized. aborting.')
        return false
      }

      // fetch prices and init rooms
      await Promise.all([
        context.dispatch('rooms/init', null, { root: true }),
        context.dispatch('account/fetchPrices', null, { root: true })
      ])

      // async
      context.dispatch('countries')

      // idle timer
      if (idleIntervalId) {
        window.clearInterval(idleIntervalId)
      }

      idleIntervalId = window.setInterval(async () => {
        const diff = Date.now() - context.state.idle

        if (diff >= IDLE_LOGOUT) {
          await context.dispatch('logout')
          router.push({ name: 'Logout' })
        }
      }, IDLE_INTERVAL_CHECK)

      initialized = true
    }
  },
  mutations: {
    countries (state, payload) {
      state.countries = Object.values(payload)
        .sort((a, b) => a.country_fr_label.localeCompare(b.country_fr_label))
      ls.setItem('countries', JSON.stringify(state.countries))
    },
    loginAsGuest (state, { email }) {
      state.valid = true
      state.checked = true
      state.guest = true
      state.stripeSubId = ''
      state.userName = ''
      state.userEmail = email
      state.userCompany = ''
      state.userLocale = DEFAULT_LOCALE
      state.userAddressStreet = ''
      state.userAddressState = ''
      state.userAddressZip = ''
      state.userAddressCity = ''
      state.userAddressCountry = DEFAULT_COUNTRY
      state.userPremium = false
      state.userPremiumEndsAt = null

      ls.setItem('session', JSON.stringify(state))
    },
    login (state, payload) {
      state.valid = true
      state.checked = true
      state.guest = false
      state.stripeSubId = payload.stripe_subscription_id || ''
      state.userName = payload.name || ''
      state.userEmail = payload.email
      state.userCompany = payload.company || ''
      state.userLocale = payload.locale || DEFAULT_LOCALE
      state.userAddressStreet = payload.address || ''
      state.userAddressState = '' // not available in Callr API
      state.userAddressZip = payload.zipcode || ''
      state.userAddressCity = payload.city || ''
      state.userAddressCountry = payload.country || DEFAULT_COUNTRY
      state.userPremium = payload.premium === true
      state.userPremiumEndsAt = payload.premium_end

      ls.setItem('session', JSON.stringify(state))
    },
    logout (state) {
      state.valid = false
      state.checked = false
      state.guest = true
      state.stripeSubId = ''
      state.userName = ''
      state.userEmail = ''
      state.userCompany = ''
      state.userLocale = DEFAULT_LOCALE
      state.userAddressStreet = ''
      state.userAddressState = ''
      state.userAddressZip = ''
      state.userAddressCity = ''
      state.userAddressCountry = DEFAULT_COUNTRY
      state.userPremium = false
      state.userPremiumEndsAt = null

      // ls.removeItem('session')
      ls.setItem('session', JSON.stringify({}))

      window.clearInterval(idleIntervalId)
      idleIntervalId = 0

      initialized = false
    },
    authChecked (state) {
      state.checked = true
    },
    resetIdle (state) {
      state.idle = Date.now()
    },
    updateUser (state, payload) {
      state.stripeSubId = payload.stripe_subscription_id || ''
      state.userName = payload.name || ''
      state.userEmail = payload.email
      state.userCompany = payload.company || ''
      state.userLocale = payload.locale || DEFAULT_LOCALE
      state.userAddressStreet = payload.address || ''
      state.userAddressState = '' // not available in Callr API
      state.userAddressZip = payload.zipcode || ''
      state.userAddressCity = payload.city || ''
      state.userAddressCountry = payload.country || DEFAULT_COUNTRY
      state.userPremium = payload.premium === true
      state.userPremiumEndsAt = payload.premium_end

      ls.setItem('session', JSON.stringify(state))
    }
  }
}

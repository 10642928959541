<template>
  <div class="DS_switch-root">
    <div
      class="DS_switch-frame"
      @click="onClickOnSwitch"
    >
      <input
        ref="switchModelInput"
        type="checkbox"
        class="DS_switch-model-input"
        :checked="checked"
        @change="$emit('change', $event.target.checked)"
      >
      <label class="DS_switch-box" />
      <label class="DS_switch-disc" />
    </div>
    <label
      v-if="label"
      class="DS_switch-label"
      @click="onClickOnSwitch"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'DsSwitch',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.autofocus === true) {
      this.focusModelInput()
    }
  },
  methods: {
    onClickOnSwitch () {
      this.$refs.switchModelInput.click()
      this.focusModelInput()
    },
    focusModelInput () {
      this.$refs.switchModelInput.focus()
    }
  }
}
</script>

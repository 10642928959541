const state = {
  isErrorPopinOpened: false,
  errorPopinMessage: null,
  popinType: 'error' // error, info
}

const mutations = {
  openErrorPopin (state, payload) {
    state.popinType = 'error'
    state.isErrorPopinOpened = true
    state.errorPopinMessage = payload.message
  },
  openInfoPopin (state, payload) {
    state.popinType = 'info'
    state.isErrorPopinOpened = true
    state.errorPopinMessage = payload.message
  },
  closeErrorPopin (state) {
    state.isErrorPopinOpened = false
    state.errorPopinMessage = null
  }
}

export default {
  namespaced: true,

  state,
  mutations
}

/**
 * Auto-include Design System components
 */

import Vue from 'vue'

const componentsContext = require.context('./', false, /\.vue$/)

componentsContext.keys().forEach(function (fileName) {
  const componentConfig = componentsContext(fileName)

  Vue.component(componentConfig.default.name, componentConfig.default)
})

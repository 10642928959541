import Vue from 'vue'
import router from '@/router'
import api from '@/plugins/api'
import { oldestConnected } from '@/helpers'

const MAX_FETCH_ERRORS = 10
const REFRESH_INTERVAL = 10 * 1000 // 10s

const ls = window.localStorage

const createRoomObject = {
  name: '',
  email: '',
  notify_connect: true
}

export default {
  namespaced: true,

  state: {
    loading: false,
    rooms: {},
    durations: {},
    errorCount: 0
  },

  getters: {
    permanentRooms: (state) => Object.values(state.rooms)
      .filter((room) => Object.keys(room.connected).length === 0 && room.next_meeting.substr(0, 5) === '0000-')
      .sort((a, b) => a.name.localeCompare(b.name)),
    ongoingConferences: (state) => Object.values(state.rooms)
      .filter((room) => Object.keys(room.connected).length > 0)
      .sort((a, b) => a.name.localeCompare(b.name)),
    upcomingConferences: (state) => Object.values(state.rooms)
      .filter((room) => Object.keys(room.connected).length === 0 && room.next_meeting.substr(0, 5) !== '0000-')
      .sort((a, b) => a.next_meeting < b.next_meeting),
    endedConferences: (state) => [],
    duration: (state) => (roomId) => Object.prototype.hasOwnProperty.call(state.durations, roomId) ? state.durations[roomId] : 0
  },

  actions: {
    async init (context) {
      if (context.rootState.session.guest) {
        const rooms = JSON.parse(ls.getItem('rooms'))
        if (rooms) {
          context.commit('set', { rooms })
        }
      } else {
        // ls.removeItem('rooms')
        // make sure the rooms are emptied
        // otherwise, they would magically reappear...
        ls.setItem('rooms', JSON.stringify({}))
      }

      await context.dispatch('search')
      context.dispatch('roomDurations')
    },

    roomDurations (context) {
      if (!context.rootState.session.valid) {
        // abort timer if session is not valid anymore
        console.warn('stopping loop roomDurations() (session not valid)')
        return
      }

      const durations = {}

      // context.state.rooms no iterable when empty?!
      for (const room of Object.values(context.state.rooms)) {
        const oldest = oldestConnected(room.connected)

        if (!oldest) {
          durations[room.hash] = 0
          continue
        }

        durations[room.hash] = Date.now() - oldest.getTime()
      }

      context.commit('set', { durations })

      window.setTimeout(() => context.dispatch('roomDurations'), 1000)
    },

    async register (context, { roomId }) {
      // do we have the room requested?
      let room = context.state.rooms[roomId]

      // if we dont, commit the room in []rooms
      if (room === undefined) {
        room = (await api.get(`/v1/room/${roomId}`)).data
        context.commit('room', room)
      }
    },

    // force refresh one room
    async refresh (context, { roomId }) {
      const room = (await api.get(`/v1/room/${roomId}`)).data
      context.commit('room', room)

      return room
    },

    async search (context) {
      if (!context.rootState.session.valid) {
        // stop the loop if we are not connected anymore
        console.warn('stopping loop search() (session not valid)')
        return
      }

      if (context.state.errorCount >= MAX_FETCH_ERRORS) {
        // make sure we are logged out. this will stop the timers.
        context.dispatch('session/logout', {}, { root: true })
        // move to the logout page
        router.push({ name: 'Logout' })
        return
      }

      try {
        context.commit('set', { loading: true })

        // fetch rooms
        const apiRooms = (await api.get('/v1/rooms')).data

        // disconnected?
        if (!context.rootState.session.valid) {
          return
        }

        // apiRooms => state
        for (const room of apiRooms) {
          context.commit('room', room)
        }

        // rooms I have in state, but not in apiRooms
        const outstandingRooms = Object.keys(context.state.rooms)
          .filter((hash) => apiRooms.find((item) => item.hash === hash) === undefined)

        // fetch outstanding rooms manually
        // either rooms for guest (because they have no api rooms)
        // or rooms manually added (using URL) by connected users
        // those rooms will be store in vuex, but not in localstorage
        // if they reload the page: too bad!
        for (const roomId of outstandingRooms) {
          let room
          try {
            room = (await api.get(`/v1/room/${roomId}`)).data
          } catch (err) {
            // was this room deleted?
            if (err.message === 'ROOM_NOT_FOUND') {
              context.commit('deleteRoom', roomId)
              continue
            }
            throw err
          }
          // disconnected?
          if (!context.rootState.session.valid) {
            return
          }
          context.commit('room', room)
        }

        if (context.rootState.session.guest) {
          // store rooms
          context.commit('storeRoomsLocalStorage')
        }
        context.commit('set', { errorCount: 0 })
      } catch (err) {
        context.commit('set', { errorCount: context.state.errorCount + 1 })
        throw err
      } finally {
        context.commit('set', { loading: false })
        window.setTimeout(() => context.dispatch('search'), REFRESH_INTERVAL)
      }
    },

    async create (context, payload) {
      const room = Object.assign({}, createRoomObject)

      if (typeof payload === 'object') {
        for (const key in room) {
          if (Object.prototype.hasOwnProperty.call(payload, key) &&
            typeof room[key] === typeof payload[key]) {
            room[key] = payload[key]
          }
        }
      }

      const now = new Date()

      if (!room.name) {
        room.name = 'Conférence ' + now.toLocaleDateString(
          context.rootGetters['session/jsLocale']
        )
      }
      if (!room.email) {
        room.email = context.rootState.session.userEmail
      }

      const createdRoom = (await api.post('/v1/rooms', room)).data

      context.commit('room', createdRoom)
      return createdRoom
    },

    async mute (context, { roomId, ids }) {
      return (await api.post(`/v1/room/${roomId}/participants/mute`, ids)).data
    },

    async unmute (context, { roomId, ids }) {
      return (await api.post(`/v1/room/${roomId}/participants/unmute`, ids)).data
    },

    async pause (context, { roomId, ids }) {
      return (await api.post(`/v1/room/${roomId}/participants/pause`, ids)).data
    },

    async unpause (context, { roomId, ids }) {
      return (await api.post(`/v1/room/${roomId}/participants/unpause`, ids)).data
    },

    async hangup (context, { roomId, ids }) {
      return (await api.post(`/v1/room/${roomId}/participants/hangup`, ids)).data
    },

    async save (context, { roomId, properties }) {
      const room = (await api.put(`/v1/room/${roomId}`, properties)).data
      context.commit('room', room)
    },

    async invite (context, { roomId, message, datetime, participants }) {
      const errors = []

      for (const participant of participants) {
        participant.message = message
        participant.datetime = datetime
        try {
          await api.post(`/v1/room/${roomId}/invite`, participant)
        } catch (err) {
          errors.push({ participant, err })
        }
      }

      return errors
    },

    async uninvite (context, { roomId, invitedId }) {
      return (await api.delete(`/v1/room/${roomId}/invite/${invitedId}`))
    },

    async delete (context, { roomId }) {
      await api.delete(`/v1/room/${roomId}`)
      context.commit('deleteRoom', roomId)
    },

    async mediaUpload (context, { roomId, file }) {
      const reader = new FileReader()
      const readData = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result)
        reader.onerror = (err) => reject(err)
      })
      reader.readAsDataURL(file)

      let fileContent = await readData

      // remove Data-URL declaration
      fileContent = fileContent.replace(/^data:.+?\/.+?;base64,/, '')

      const room = (await api.post(`/v1/room/${roomId}/media`, {
        for: 'welcome',
        data: fileContent
      })).data

      context.commit('room', room)

      return true
    }
  },

  mutations: {
    room (state, room) {
      Vue.set(state.rooms, room.hash, room)
    },
    filterRooms (state, remoteRooms) {
      const existingRooms = Object.keys(state.rooms)
      const deletedRooms = existingRooms.filter((room) => remoteRooms.find((item) => item.hash === room) === undefined)

      for (const room of deletedRooms) {
        Vue.delete(state.rooms, room)
      }
    },
    deleteRoom (state, roomId) {
      Vue.delete(state.rooms, roomId)
    },
    set (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
    reset (state) {
      state.durations = {}
      state.errorCount = 0
    },
    storeRoomsLocalStorage (state) {
      ls.setItem('rooms', JSON.stringify(state.rooms))
    }
  }
}

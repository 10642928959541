<template>
  <div class="C_auth-root">
    <ConfcallrLogo color="white" />
    <vue-loading
      type="bars"
      color="#fff"
    />
  </div>
</template>

<script>
import ConfcallrLogo from '@/components/ConfcallrLogo.vue'

export default {
  components: {
    ConfcallrLogo
  },
  computed: {
    start () {
      return !!this.$route.params.email
    }
  },
  mounted () {
    this.authCheck()
  },
  methods: {
    authSuccessful () {
      if (this.$route.params.redirect && this.$route.params.redirect.path) {
        this.$router.replace(this.$route.params.redirect)
      } else {
        this.$router.replace({ name: 'Conferences', params: { start: this.start } })
      }
    },
    async authCheck () {
      try {
        const valid = await this.$store.dispatch('session/authCheck')

        if (valid) {
          // if we have a valid session+cookie, let's move on
          // we're already signed in
          this.$store.dispatch('session/init')
          this.authSuccessful()
        } else {
          this.$router.replace({ name: 'Login', params: this.$route.params })
        }
      } catch (err) {
        // this.loading = false
        this.$store.commit('app/openErrorPopin', err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/commons/auth.scss";
</style>

<template>
  <div>
    <input
      :value="value"
      :type="type"
      class="DS_input"
      :class="{ 'DS_input--gray': gray }"
      v-bind="$attrs"
      v-on="listeners"
    >
  </div>
</template>

<script>
export default {
  name: 'DsInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      validator (value) {
        return ['text', 'email', 'password', 'number'].includes(value)
      },
      required: true
    },
    gray: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: (event) => {
          if (this.type === 'number') {
            if (event.target.value === '') {
              this.$emit('input', null)
            } else {
              this.$emit('input', Number(event.target.value))
            }
          } else {
            this.$emit('input', event.target.value)
          }
        }
      }
    }
  }
}
</script>
